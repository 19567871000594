import {
    DISPLAY_GAME_LIST,
    DISPLAY_GAME
} from './gameActions';

import {
    DISPLAY_TOURNAMENT_GAMES, DISPLAY_TOURNAMENT_LIST
} from "./tournamentActions";

const initialState = {
    page: 'tournament_list',
    tournamentId: null,
    gameId: null
};

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case DISPLAY_TOURNAMENT_LIST:
            return {
                ...state,
                page: 'tournament_list',
                tournamentId: null,
                gameId: null
            };

        case DISPLAY_GAME_LIST:
            let params = action.payload.tournamentId ? {tournamentId: action.payload.tournamentId} : {};
            return {
                ...state,
                page: 'game_list',
                gameId: null,
                ...params
            };


        case DISPLAY_GAME:
            return {
                ...state,
                page: 'game',
                gameId: action.payload.gameId
            };

        default:
            return state;
    }
}