import React, { Component } from 'react';
import {fetchTournament, displayTournament, fetchTournamentList} from "./tournamentActions";
import connect from "react-redux/es/connect/connect";
import { Table, Button } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import {displayGameList} from "./gameActions";


class TournamentList extends Component {
  componentDidMount() {
    this.props.dispatch(fetchTournamentList());
  }

  render() {
    const { error, loading, tournamentList } = this.props.tournamentList;
    let rows = tournamentList.map((tournament) =>
        <tr>
            <td>{tournament.name}</td>
            <td>{tournament.site}</td>
            <td><Button color="primary" onClick={() => this.props.dispatch(displayGameList(tournament.id))}>Open</Button></td>
        </tr>
    );
    return (
          <div className="TournamentList">
              <Container>
              <h1>Tournaments</h1>
              <Table>
                  <thead>
                    <tr>
                        <th>Name</th>
                        <th>Site</th>
                        <th/>
                    </tr>
                  </thead>
                  {rows}
              </Table>
              </Container>
          </div>
    );
  }
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
    return {fetchTournamentList, dispatch}
};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentList);