import {
    FETCH_TOURNAMENT_LIST_SUCCESS,
    FETCH_TOURNAMENT_LIST_FAILURE,
    FETCH_TOURNAMENT_LIST_BEGIN
} from './tournamentActions';

const initialState = {
  tournamentList: [],
  loading: false,
  error: null
};

export default function tournamentListReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_TOURNAMENT_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

      case FETCH_TOURNAMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        tournamentList: action.payload,
          error: null
      };

    case FETCH_TOURNAMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        tournamentList: []
      };

    default:
      return state;
  }
}