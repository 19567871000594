import { handleErrors } from './utils';

export const FETCH_TOURNAMENT_LIST_BEGIN   = 'FETCH_TOURNAMENT_LIST_BEGIN';
export const FETCH_TOURNAMENT_LIST_SUCCESS = 'FETCH_TOURNAMENT_LIST_SUCCESS';
export const FETCH_TOURNAMENT_LIST_FAILURE = 'FETCH_TOURNAMENT_LIST_FAILURE';

export const DISPLAY_TOURNAMENT_GAMES = 'DISPLAY_TOURNAMENT_GAMES';
export const DISPLAY_TOURNAMENT_LIST = 'DISPLAY_TOURNAMENT_LIST';


export const fetchTournamentListBegin = () => ({
  type: FETCH_TOURNAMENT_LIST_BEGIN
});


export const displayTournamentList = () => ({
   type: DISPLAY_TOURNAMENT_LIST
});


export const displayTournamentGames = (tournamentId) => ({
    type: DISPLAY_TOURNAMENT_GAMES,
    payload: { tournamentId }
});



export const fetchTournamentListSuccess = TournamentListData => ({
  type: FETCH_TOURNAMENT_LIST_SUCCESS,
  payload: TournamentListData
});



export const fetchTournamentListFailure = error => ({
  type: FETCH_TOURNAMENT_LIST_FAILURE,
  payload: { error }
});


export function fetchTournamentList() {
  return dispatch => {
    dispatch(fetchTournamentListBegin());
    return fetch(`/api/tournament/`)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchTournamentListSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchTournamentListFailure(error)));
  };
}

