import Game from './Game';
import GameList from './GameList';
import React from "react";
import queryString from "query-string";
import connect from "react-redux/es/connect/connect";
import {fetchGame} from "./gameActions";
import TournamentList from "./TournamentList";
import {Container, Row, Col} from 'reactstrap';

import certabo from "./img/certabo.png";
import tournament_image from "./img/tournament.jpeg";
import Provider from "react-redux/es/components/Provider";


class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let part, right_logo, tournament;
        if (this.props.app.page === 'game') {
            part = <Game gameId={this.props.app.gameId} />;
        } else if (this.props.app.page === 'game_list') {
            part = <GameList />;
        } else if (this.props.app.page === 'tournament_list') {
            part = <TournamentList/>;
        }
        if (this.props.app.page === 'game_list' || this.props.app.page === 'game') {
            tournament = this.props.tournamentList.tournamentList.find(o => o.id === this.props.app.tournamentId?o:undefined);
        } else {
            tournament = undefined;
        }
        if (tournament) {
            right_logo = <Col>
                <img id="right-logo" src={tournament.logo} />
            </Col>;
        } else {
            right_logo = <Col/>;
        }
        return  (<Container>
            <Row id="logoheader">
                <Col>
                <img id="left-logo" src={certabo} />
                </Col>
                { right_logo }
            </Row>
            {part}
        </Container>);
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
    return {fetchGame, dispatch}
};

export default connect(mapStateToProps, mapDispatchToProps)(App);