import React, { Component } from 'react';
import {fetchGameList, displayGame, displayGameList} from "./gameActions";
import Chessdiagram from "react-chessdiagram";
import connect from "react-redux/es/connect/connect";
import { Table, Button } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import {displayTournamentList} from "./tournamentActions";


class GameList extends Component {
  componentDidMount() {
    this.props.dispatch(fetchGameList(this.props.app.tournamentId));
  }

  render() {
    const { error, loading, gameList } = this.props.gameList;
    let rows = gameList.map((game) =>
        <tr>
            <td>{game.round?game.round:''}</td>
            <td>{game.white}</td>
            <td>{game.black}</td>
            <td>{game.result}</td>
            <td><Button color="primary" onClick={() => this.props.dispatch(displayGame(game.id))}>View</Button></td>
            <td><a href={`/api/game/${game.id}/pgn/`}>PGN</a></td>
        </tr>
    );
    return (
          <div className="GameList">
              <Container>
                  <Button id="back-to-tournament-list" color="primary" onClick={()=>this.props.dispatch(displayTournamentList())}>Back to Tournament list</Button>
                  <h1>Game list</h1>
                  <Table>
                      <thead>
                        <tr>
                            <th>Round</th>
                            <th>White</th>
                            <th>Black</th>
                            <th>Result</th>
                            <th></th>
                            <th></th>
                        </tr>
                      </thead>
                      {rows}
                  </Table>
              </Container>
          </div>
    );
  }
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
    return {fetchGameList, dispatch}
};

export default connect(mapStateToProps, mapDispatchToProps)(GameList);