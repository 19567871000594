import { combineReducers } from "redux";
import gameReducer from "./gameReducer";
import gameListReducer from "./gameListReducer";
import appReducer from "./appReducer";
import tournamentListReducer from "./tournamentListReducer";

export default combineReducers({
    game: gameReducer,
    gameList: gameListReducer,
    app: appReducer,
    tournamentList: tournamentListReducer,
});