import {stringify} from 'query-string';

export const FETCH_GAME_BEGIN = 'FETCH_GAME_BEGIN';
export const FETCH_GAME_SUCCESS = 'FETCH_GAME_SUCCESS';
export const FETCH_GAME_FAILURE = 'FETCH_GAME_FAILURE';

export const FETCH_GAME_LIST_BEGIN = 'FETCH_GAME_LIST_BEGIN';
export const FETCH_GAME_LIST_SUCCESS = 'FETCH_GAME_LIST_SUCCESS';
export const FETCH_GAME_LIST_FAILURE = 'FETCH_GAME_LIST_FAILURE';

export const DISPLAY_GAME = 'DISPLAY_GAME';
export const DISPLAY_GAME_LIST = 'DISPLAY_GAME_LIST';


export const fetchGameBegin = () => ({
    type: FETCH_GAME_BEGIN
});


export const fetchGameListBegin = () => ({
    type: FETCH_GAME_BEGIN
});


export const displayGame = gameId => ({
    type: DISPLAY_GAME,
    payload: {gameId}
});


export const displayGameList = (tournamentId) => ({
    type: DISPLAY_GAME_LIST,
    payload: {tournamentId}
});


export const fetchGameSuccess = gameData => ({
    type: FETCH_GAME_SUCCESS,
    payload: {gameData}
});


export const fetchGameListSuccess = gameList => ({
    type: FETCH_GAME_LIST_SUCCESS,
    payload: gameList
});


export const fetchGameFailure = error => ({
    type: FETCH_GAME_FAILURE,
    payload: {error}
});


export const fetchGameListFailure = error => ({
    type: FETCH_GAME_LIST_FAILURE,
    payload: {error}
});


export function fetchGame(gameId) {
    return dispatch => {
        dispatch(fetchGameBegin());
        return fetch(`/api/game/${gameId}/`)
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchGameSuccess(json));
                return json;
            })
            .catch(error => dispatch(fetchGameFailure(error)));
    };
}

export function fetchGameList(tournamentId) {
    let query = tournamentId ? stringify({'tournament': tournamentId}) : "";
    let params = query ? `?${query}` : '';
    return dispatch => {
        dispatch(fetchGameListBegin());
        return fetch(`/api/game/${params}`)
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchGameListSuccess(json));
                return json;
            })
            .catch(error => dispatch(fetchGameListFailure(error)));
    };
}


// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}