import {
    FETCH_GAME_BEGIN,
    FETCH_GAME_SUCCESS,
    FETCH_GAME_FAILURE, DISPLAY_GAME_LIST
} from './gameActions';

const initialState = {
  pgn_data: null,
    white: null,
    black: null,
    result: '*',
  loading: false,
  error: null
};

export default function gameReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_GAME_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_GAME_SUCCESS:
      return {
        ...state,
        loading: false,
        pgn_data: action.payload.gameData.pgn_data,
          white: action.payload.gameData.white,
          black: action.payload.gameData.black,
          result: action.payload.gameData.result,
      };

    case FETCH_GAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case DISPLAY_GAME_LIST:
    default:
      return state;
  }
}