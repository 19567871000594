import React, { Component } from 'react';
import Chessdiagram from 'react-chessdiagram';
import {displayGameList, fetchGame} from './gameActions';
import {connect} from 'react-redux';
import { Button } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';

const lightSquareColor = '#FFFFFF'; // light blue
const darkSquareColor = '#98f2fe'; // dark blue
const gameHistory = true;


const flip = false;
const squareSize = 50;


class Game extends Component {
  componentDidMount() {
      const gameId = this.props.gameId;
      let self = this;
      this.props.dispatch(fetchGame(gameId));
      this.timer = setInterval(() => self.props.dispatch(fetchGame(gameId)), 5000);
  }

  componentWillUnmount() {
      clearInterval(this.timer);
  }

  render() {
    const { error, loading, pgn_data, white, black, result } = this.props.game;
    let diagram;
    if (pgn_data) {
        diagram = <Chessdiagram flip={flip} pgn={pgn_data} squareSize={squareSize}
            lightSquareColor={lightSquareColor} darkSquareColor={darkSquareColor} gameHistory={gameHistory} startMove={'last'} fen={null}/>;

    } else {
        diagram = <Chessdiagram flip={flip} squareSize={squareSize} fen={'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'}
            lightSquareColor={lightSquareColor} darkSquareColor={darkSquareColor} />;
    }
    return (
        <div className="Game" key={this.props.gameId}>
        <Container>
            <Button id="back-to-game-list" color="primary" onClick={()=>this.props.dispatch(displayGameList())}>Back to Game list</Button>
            <Row>
            <h1>Game broadcast</h1>
            </Row>
            <Row>
            <h4>{white} - {black}{result !== '*'?`(${result})`:''}</h4>
            </Row>
            <Row>
            {diagram}
            </Row>
            <Row>
                <small><a href="https://commons.wikimedia.org/wiki/Category:SVG_chess_pieces">Chess pieces</a> by jurgenwesterhof under <a href="https://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a></small>
            </Row>
        </Container>
        </div>
    );
  }
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
    return {fetchGame, dispatch}
};

export default connect(mapStateToProps, mapDispatchToProps)(Game);