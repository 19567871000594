import {
    FETCH_GAME_LIST_SUCCESS,
    FETCH_GAME_LIST_FAILURE,
    FETCH_GAME_LIST_BEGIN
} from './gameActions';

const initialState = {
  gameList: [],
  loading: false,
  error: null
};

export default function gameListReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_GAME_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

      case FETCH_GAME_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        gameList: action.payload,
          error: null
      };

    case FETCH_GAME_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        gameList: []
      };

    default:
      return state;
  }
}